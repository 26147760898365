<template>
  <div>
    <loader-info v-if="isLoading" />
    <div v-else>
      <document-item
        v-for="doc in documentsList"
        :key="doc.documentId"
        :current-doc="doc"
        :entity-id="entityId"
        :entity-class="entityClass"
        :on-success="onSuccessUpload"
      />
      <modal-document-upload
        :id="userDocUploadId"
        entity-type="PERSON"
        :entity-id="entityId"
        :document-name="documentToUpload.documentName"
        :document="documentToUpload.document"
        :on-success="updateDoc"
      />
    </div>
  </div>
</template>

<script>
import lodash from 'lodash';
import {
  decodeFormFieldError, isNotEmpty, omitNull, removeProp,
  validateFormFieldState,
} from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const DocumentItem = () => import('@/components/documents/DocumentItem.vue');
const ModalDocumentUpload = () => import('@/components/documents/ModalDocumentUpload.vue');

export default {
  name: 'UserDocumentsList',
  components: {
    DocumentItem,
    LoaderInfo,
    ModalDocumentUpload,
  },
  props: {
    entityClass: String,
    entityId: String,
    readOnly: Boolean,
  },
  data() {
    return {
      formUserData: null,
      clientValidation: {
        formUserData: {
          docIdentity: {
            docNumber: {
              // required,
            },
            expireDate: {
              // checkExpired,
            },
          },
        },
      },
      serverValidation: {},
      documentToUpload: {
        document: null,
        documentName: null,
      },
      submitted: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    isLoading() {
      return this.$store.getters['documents/isLoading'];
    },
    documentsList() {
      const docs = this.$store.getters['documents/getDocumentList'](this.entityClass, this.entityId);
      console.log('documentsList', docs);
      return docs.filter((doc) => {
        const { documentInfo } = doc;
        const { documentEntityType, documentType } = documentInfo;
        if (documentEntityType === 'PERSON') {
          if (documentType === 'IDENTITY') {
            return false;
          }
        }
        return true;
      });
    },
    currentIdentityDoc() {
      return this.$store.getters['subject/currentIdentityDoc'];
    },
    isDisabled() {
      // return (this.readOnly || !this.editMode);
      return this.readOnly;
    },
    userDocUploadId() {
      return 'userDocsModal';
    },
  },
  validations() {
    return this.rules;
  },
  created() {
    this.populateForm();
  },
  methods: {
    openModalUpload(doc, documentName) {
      this.documentToUpload = {
        document: doc,
        documentName,
      };
      this.$bvModal.show(this.userDocUploadId);
    },
    fetchData() {
      this.$store.dispatch('documents/loadDocuments',
        { entityClass: this.entityClass, entityId: this.entityId })
        .then((data) => {
          console.log('Response', data);
        },
        (error) => {
          console.log('error', error);
        });
    },
    onSuccessUpload() {
      if (!this.$store.getters['auth/isLogged']) {
        return;
      }

      this.$store.dispatch('subject/loadSubject');
    },
    onSuccessAdd(data) {
      this.$store.dispatch('documents/setDocuments', {
        entityClass: this.entityClass,
        entityId: this.entityId,
        docs: data,
      });
    },
    updateDoc(docUpdated) {
      return this.$store.dispatch('subject/updatePersonDocs', { identityDoc: docUpdated });
    },
    onUploadDoc() {
      this.openModalUpload(this.currentIdentityDoc, 'Documento d\'identità');
    },
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    clearServerErrors() {
      // Clearing the server validation removes the rules and
      // therefore server errors no longer contribute to validation state.
      this.serverValidation = {};
      // Clearing the server errors from the data isn't necessary either
      // but again makes for good housekeeping and we'd need to do this
      // if submitting this data to the server.
      removeProp(this.formData, 'serverErrors');
    },
    populateForm() {
      const person = this.$store.getters['subject/currentPerson'];
      const docIdentity = this.$store.getters['subject/currentIdentityDoc'];
      const deepcloned = lodash.cloneDeep(person);

      const empty = {
        docIdentity: {
          docNumber: null,
          expireDate: null,
        },
      };

      if (isNotEmpty(deepcloned)) {
        this.formUserData = { ...empty, ...omitNull(deepcloned) };
        if (isNotEmpty(docIdentity) && isNotEmpty(docIdentity.additionalInfos)) {
          this.$set(this.formUserData.docIdentity, 'docNumber', docIdentity.additionalInfos.docNumber);
          this.$set(this.formUserData.docIdentity, 'expireDate', docIdentity.additionalInfos.expireDate);
          // this.formUserData.docIdentity.docNumber = docIdentity.additionalInfos.docNumber;
          // this.formUserData.docIdentity.expireDate = docIdentity.additionalInfos.expireDate;
        }
      } else {
        this.formUserData = { ...empty };
      }
    },
    submitForm() {
      if (this.isDisabled) return;
      this.clearServerErrors();
      if (this.$v.$invalid) return;

      console.log('submitForm', this.formUserData);
      this.submitted = true;
      const { docIdentity, ...person } = this.formUserData;
      this.$store.dispatch('subject/saveSubject', {
        person,
      }).then(
        () => {
          const doc = this.$store.getters['subject/currentIdentityDoc'];
          const toUpdate = { ...doc, additionalInfos: { ...docIdentity } };
          this.$store.dispatch('documents/updateDocument', {
            document: toUpdate,
            entityClass: 'PERSON',
            entityId: person.personId,
          }).then((docUpdated) => {
            this.updateDoc(docUpdated).then(() => {
              this.submitted = false;
            },
            (responseError) => {
              this.processError(responseError.errors);
              this.submitted = false;
            });
          },
          (responseError) => {
            this.processError(responseError.errors);
            this.submitted = false;
          });
        },
        (responseError) => {
          this.processError(responseError.errors);
          this.submitted = false;
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
